<template>
    <div class="table-overflow" style="margin-bottom: 200px;">
    <transition name="modal fade">
        <div class="modal-content">
          <div class="modal-wrapper">
            <div class="modal-container" >

              <div class="modal-header">
                <slot name="header">
                  
                </slot>
              </div>

              <div class="modal-body">
                <slot name="body">
                  
                </slot>
              </div>

              <div class="modal-footer">
                <slot name="footer">
                  
                 <!-- <button class="modal-default-button btn btn-outline-secondary" @click="$emit('close')">
                    Cancelar
                  </button> -->
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
      </div>
</template>

<style>
/*.table-overflow {*/
/*  max-height:400px;*/
/*  overflow-y:auto;*/
/*}*/
</style>


<script>
export default {
  name: "Modal",
  data() {
    return {
      show: false
    };
  },
  methods: {
  
  }
};
</script>

