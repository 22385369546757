<template>

  <div>
    <NavBar :thisTitle="title" ></NavBar>
  </div>


  <div class="container mt-5  table-overflow ">


    <h2 class="mt-3">Lista de Leitos</h2>
    <i class="h6"><strong>Sistema de Classificação do Paciente FUGULIN et al (1994)</strong></i>

    <form @submit.prevent="listarSearch">
      <div class="row d-flex justify-content-end">
        <div class="col-12 col-md-2 my-1">
          Começo:
          <input type="date" class="form-control" name="searchStart" id="searchStart" v-model="searchStart">
        </div>
        <div class="col-12 col-md-2 my-1">
          Fim:
          <input type="date" class="form-control" name="searchEnd" id="searchEnd" v-model="searchEnd">
        </div>
        <div class="col-12 col-md-2 my-1">
          Complexidade:
          <select class="form-select" v-model="searchComplexidade">
            <option value="">Todas</option>
            <option>Alta dependência</option>
            <option>Intensivo</option>
            <option>Semi-Intensivo</option>
            <option>Intermediário</option>
            <option>Mínimo</option>
          </select>
        </div>
        <div class="col-12 col-md-2 my-1">
          &nbsp;
          <button type="submit" class="btn btn-primary w-100">Pesquisar</button>
        </div>
        <div class="col-12 col-md-2 my-1">
          &nbsp;
          <a href="/about" class="btn btn-danger w-100">Limpar Filtro</a>
        </div>
      </div>
    </form>
    <div class="table-responsive col-md-12 mt-2">
      <table class="table table-bordered table-striped w-100 ">
        <thead>
        <tr>
          <th  class="centro">Número do Leito</th>
          <th  class="w-75 centro">Paciente</th>
          <th  class="centro">Data de Nascimento</th>
          <th  class="centro">Data</th>
          <th  class="centro">Estado Mental</th>
          <th  class="centro">Oxigenação</th>
          <th  class="centro">Sinais Vitais</th>
          <th  class="centro">Motilidade</th>
          <th  class="centro">Deambulação</th>
          <th  class="centro">Alimentação</th>
          <th  class="centro">Cuidado Corporal</th>
          <th  class="centro">Eliminação</th>
          <th  class="centro">Terapêutica</th>
          <th  class="centro">Total</th>
          <th  class="centro">Complexidade Assistencial </th>
          <th  class="centro">Ação</th>
        </tr>
        </thead>
        <tbody>

        <tr scope="col" v-for="fugulim of fugulims" :key="fugulim.id" >
          <td>{{fugulim.numero_leito}}</td>
          <td class="text-start">{{fugulim.nome}}</td>
          <td>{{formatDate(fugulim.data_nascimento) }}</td>
          <td>{{formatDate(fugulim.created_at)}}</td>
          <td>{{fugulim.estado_mental}}</td>
          <td>{{fugulim.oxigenacao}}</td>
          <td>{{fugulim.sinais_vitais}}</td>
          <td>{{fugulim.motilidade}}</td>
          <td scope="col">{{fugulim.deambulacao}}</td>
          <td scope="col">{{fugulim.alimentacao}}</td>
          <td scope="col" >{{fugulim.cuidado_corporal}}</td>
          <td scope="col" >{{fugulim.eliminacao}}</td>
          <td scope="col">{{fugulim.terapeutica}}</td>
          <td scope="col">{{fugulim.total}}</td>
          <td scope="col">{{fugulim.validadorTotal}}</td>
<!--          <td class="w-50"><button type="button" class="btn btn-danger btn-sm" @click="deleteRow(fugulim.id, fugulim.numero_leito)">Excluir</button></td>-->
          <td class="w-50"><button type="button" class="btn btn-primary btn-sm" id="edit" @click="editRow(fugulim.id, fugulim)" >Editar</button></td>
        </tr>

        </tbody>
      </table>
    </div>

      <div class="d-flex justify-content-center">
        <vc-pagination :source="pagination" @navigate="navigate"></vc-pagination>
      </div>

      <div class="d-flex justify-content-between flex-row-reverse bd-highlight mb-5">
        <button type="button" class="btn btn-success" @click="imprimir()">IMPRIMIR RELATÓRIO</button>
        <a @click="home()">
          <button class="btn btn-success">Voltar</button>
        </a>
      </div>

      <modal v-if="showModal" @close="showModal = false"  >
        <template v-slot:header>
          <h5>Editando o leito: <strong class="text-dark">{{fugulim.numero_leito}}</strong></h5>
        </template>
        <template v-slot:body>
          <form>
            <div class="row">
              <div class="col-md-4">
                <label for="recipient-name" class="col-form-label text-left">Número do Leito:</label>
                <input type="text" class="form-control" id="numero_leito" name="numero_leito" v-model="fugulim.numero_leito">
              </div>
              <div class="col-md-4 order-5">
                <label for="recipient-name" class="col-form-label text-left">Estado mental:</label>
                <input type="text" class="form-control"  id="estado_mental" name="estado_mental" v-model="fugulim.estado_mental">
              </div>
              <div class="col-md-4">
                <label for="recipient-name" class="col-form-label text-left">Oxigenação:</label>
                <input type="text" class="form-control"  id="oxigenacao" name="oxigenacao" v-model="fugulim.oxigenacao">
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 order-5">
                <label for="recipient-name" class="col-form-label text-left">Sinais Vitais:</label>
                <input type="text" class="form-control"  id="sinais_vitais" name="sinais_vitais" v-model="fugulim.sinais_vitais">
              </div>
              <div class="col-md-4">
                <label for="recipient-name" class="col-form-label text-left">Motilidade:</label>
                <input type="text" class="form-control" id="motilidade" name="motilidade" v-model="fugulim.motilidade">
              </div>
              <div class="col-md-4 order-5">
                <label for="recipient-name" class="col-form-label text-left">Deambulação:</label>
                <input type="text" class="form-control"  id="deambulacao" name="deambulacao" v-model="fugulim.deambulacao">
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label for="recipient-name" class="col-form-label text-left">Alimentação:</label>
                <input type="text" class="form-control"  id="alimentacao" name="alimentacao" v-model="fugulim.alimentacao">
              </div>
              <div class="col-md-4 order-5">
                <label for="recipient-name" class="col-form-label text-left">Deambulação:</label>
                <input type="text" class="form-control"  id="cuidado_corporal" name="cuidado_corporal" v-model="fugulim.cuidado_corporal">
              </div>
              <div class="col-md-4">
                <label for="recipient-name" class="col-form-label text-left">Eliminação:</label>
                <input type="text" class="form-control"  id="eliminacao" name="eliminacao" v-model="fugulim.eliminacao">
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 order-5">
                <label for="recipient-name" class="col-form-label text-left">Terapêutica:</label>
                <input type="text" class="form-control"  id="terapeutica" name="terapeutica" v-model="fugulim.terapeutica">
              </div>
            </div>
            <div class="row">
              <div class="bd-example  p-3" style="justify-content: start!important;">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" true-value="1" false-value="0" id="fugulim.psiq" v-model="fugulim.psiq">
                  <label class="form-check-label text-start" for="flexCheckDefault">
                    Cliente da área psiquiátrica, com intercorrência clínica ou cirúrgica associada, deve ser classificado um nível acima no SCP, iniciando-se com cuidados intermediários.
                  </label>
                </div>

                <div class="form-check mt-3 " >
                  <input class="form-check-input" type="checkbox" true-value="1" false-value="0" v-model="fugulim.berca" >
                  <label class="form-check-label text-start" for="flexCheckDefault" style="justify-content: start!important;">
                    Berçário e Unidade de Pediatria, criança menor de 6 anos e recém-nascido devem ser classificados com necessidade de cuidado intermediário, independente da presença do acompanhante.
                  </label>
                </div>
              </div>
            </div>
          </form>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="closeModal()">Cancelar</button>
          <button type="button" class="btn btn-outline-success" @click="updade()">Atualizar</button>
        </template>
      </modal>
      
    </div>

</template>


<script>

import Rota from'../service/rotas.js'
import axios from "axios"
// import NavBar  from '../components/navBar'
import Cookie from 'js-cookie';
import VcPagination from '../components/pagination.vue'
import NavBar  from '../components/navBar'
import Modal from "../components/modal.vue"

export default {




  mounted(){
    this.listar()
  },

  methods:{
   async verificaplantao(){
        const token = Cookie.get('_myapp_token');
         await   axios({
              url: '/me',
              method: 'GET',
              headers: {'Content-Type': 'application/json',Authorization: 'Bearer ' + token},
            })
            .then(response => {
              console.log(response.data)

               let profdepto = response.data.prof
               this.escala = response.data.escala
               console.log("ESCALA",this.escala)
                 for(let i =0; i< profdepto.length;i++){
                  for(let j=0;j<this.escala.length;j++){
                    let escala = this.escala[j]
                    let p = profdepto[i]
                    if(p.id == escala.profdepto_id){
                      this.scale = true
                      console.log("PRRRR",escala.start)
                      let start = escala.start.split(" ")
                      var arrayData = start[1].split(",");
                      let end = escala.end.split(" ")
                      var arrayData2 = end[1].split(",");
                      console.log(arrayData2)
                      var data = new Date();
                      let hora = data.getHours()+":"+data.getMinutes()+":"+data.getSeconds()
                      console.log(hora )
                      if(arrayData[0] >= hora || arrayData2[0] <= hora){
                        console.log('no plantão')
                        // document.getElementById("edit").disabled = true;
                      }
                  }
                 }
               }

            })
            .catch(function(error) {
                console.log("getUser" + error)
                swal.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro',
                    text: 'Não foi possível acessar a Api',
                })
            });
    },
    home(){
      this.$router.push('form');
    },

    goto(refName) {
      var element = this.$els[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },

    navigate(page){
      const token = Cookie.get('_myapp_token');
      axios({
        url:`/listarPorUser?page=${page}`,
        method: 'GET',
        headers: {'Content-Type': 'application/json',Authorization: 'Bearer ' + token},
        params:{
          searchStart: this.searchStart,
          searchEnd: this.searchEnd,
          searchComplexidade: this.searchComplexidade
        }
      })
          .then(resposta => {
            this.fugulims = resposta.data.data
            this.pagination = resposta.data
          })
          .catch(function(error) {
            console.log("ListarPorUser" + error)
            swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro',
              text: 'Não foi possível acessar a Api',
            })
          });

    },

    formatDate(dateString) {
      // Then specify how you want your dates to be formatted
      return new Date(dateString).toLocaleDateString('pt-BR', {timeZone: 'UTC'});

    },


    listarSearch(){
      const token = Cookie.get('_myapp_token');
      axios({
        url: 'https://fugulin-api.segeam.com.br/api/listarPorUser',
        method: 'GET',
        headers: {'Content-Type': 'application/json',Authorization: 'Bearer ' + token},
        params:{
          searchStart: this.searchStart,
          searchEnd: this.searchEnd,
          searchComplexidade: this.searchComplexidade
        }
      })
          .then(resposta => {
            this.fugulims = resposta.data.data
            this.pagination = resposta.data
          })
          .catch(function(error) {
            console.log("ListarPorUser" + error)
            swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro',
              text: 'Não foi possível acessar a Api',
            })
          });
    },

    listar(){


      if(this.isAdmin){
        Rota.listar().then(resposta => {
          this.fugulims = resposta.data.data
          this.pagination = resposta.data

        })

      }else {
        const token = Cookie.get('_myapp_token');
        axios({
          url: 'https://fugulin-api.segeam.com.br/api/listarPorUser',
          method: 'GET',
          headers: {'Content-Type': 'application/json',Authorization: 'Bearer ' + token},
        })
            .then(resposta => {
              this.fugulims = resposta.data.data
              this.pagination = resposta.data
            })
            .catch(function(error) {
              console.log("ListarPorUser" + error)
              swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro',
                text: 'Não foi possível acessar a Api',
              })
            });
      }


    },


    salvarColaborador(){

      this.fugulim.total =  fugulim.estado_mental + fugulim.oxigenacao + fugulim.sinais_vitais + fugulim.motilidade + fugulim.deambulacao + fugulim.alimentacao + fugulim.cuidado_corporal + fugulim.eliminacao + fugulim.terapeutica

      Rota.salvarColaborador(this.colaborador).then(resposta => {

        alert("Colaborador salvo com sucesso!!")
        this.fugulim.colaborador_id = resposta.data.colaborador.id
        console.log(this.fugulim.colaborador_id, "teste");

      })



    },

    salvarLeito(){

      Rota.salvarLeito(this.fugulim).then(resposta => {

        alert("Leito salvo com sucesso!!")

      })

    },

    Total(){


    },
    deleteRow(indx){
      Swal.fire({
        title: `Você deseja excluir o leito?`,
        // text: `Você não poderá reverter esta ação`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'SIM',
        cancelButtonText: 'CANCELAR',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          Rota.deleteLeito(indx);
          swal.fire({
            title: 'Excluido!',
            text: `Leito removido`,
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false

          });

        }
        this.$router.push('home')
      });
      // Rota.deleteLeito(indx).then(resposta => {
      //   console.log(resposta.data)
      //           Swal.fire("Sucesso", "Leito removido com sucesso!", "success")
      // }

      this.fugulim.splice(indx,1)


    },
    editRow(row,dados){

      this.fugulim = dados
      console.log('aq mano', dados)
      var dadao = this.fugulim

      this.$router.push({
        name:'Leito',
        params:{
          id: row,
          numero_leito: dados.numero_leito,
          nome: dados.nome,
          data_nascimento: dados.data_nascimento,
          estado_mental: dados.estado_mental,
          oxigenacao: dados.oxigenacao,
          sinais_vitais: dados.sinais_vitais,
          motilidade: dados.motilidade,
          deambulacao: dados.deambulacao,
          cuidado_corporal: dados.cuidado_corporal,
          alimentacao: dados.alimentacao,
          eliminacao: dados.eliminacao,
          terapeutica: dados.terapeutica,
          psiq: dados.psiq,
          berca: dados.berca,
          total: dados.total,
          validadorTotal: dados.validadorTotal,
        }})

    },
    // closeModal() {
    //   this.showModal = !this.showModal
    // },

    async imprimir(){
      const token = Cookie.get('_myapp_token');
      console.log(token);
      await axios({
        url: '/pdf/dados',
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
          Authorization: 'Bearer ' + token
        },
        params:{
          unidade:this.unidade,
          funcao:this.funcao,
          turno:this.turno,
          mes:this.mes,
          ano:this.ano
        },
        responseType: 'blob', // important
      }).then(response => {
        console.log("imprimir = "+response)
        var blob = new Blob([response.data], {
          type: 'application/pdf'
        });
        var url = window.URL.createObjectURL(blob)
        window.open(url);
      })
          .catch(function(error) {
            console.log(error)
            swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro',
              text: 'Função escolhida não possui dados cadastrados',
            })


          });


    }


  },



  name: 'Home',
  components: {
    NavBar,
    VcPagination,
    Modal
  },

  data(){
    return{
      searchStart: '',
      searchEnd: '',
      searchComplexidade: '',

      showModal: false,
      title: 'Relatório',
      isAdmin: false,
      fugulim:{
        id: '',
        nome: '',
        data_nascimento: '',
        colaborador_id: '',
        numero_leito: '',
        estado_mental: '',
        oxigenacao: '',
        sinais_vitais: '',
        motilidade: '',
        deambulacao: '',
        alimentacao: '',
        cuidado_corporal: '',
        eliminacao: '',
        terapeutica: '',
        psiq: '',
        berca: '',
        total: '',
      },

      pagination: {},
      data_atual: '',
      fulugim: [],
      fugulims: [],
      tableRows:[],


      colaborador:{
        id: '',
        coren: '',
        matricula: '',
        horas_trabalhadas: '',
        setor: '',
        unidade: '',
      },



    }
  },

  method: {
    forceRerender(){
      this.fugulim += 1
    }
  },

  created(){
    var today = new Date();
    var date = today.getDate() + ' / ' + (today.getMonth()+1) + ' / ' + today.getFullYear();
    this.verificaplantao()

  },



}
</script>

<style>
.centro{
vertical-align: middle; 
}

.tablecolor{
  background-color: #004315 !important;
  color: #fff !important;
}

.table-overflow{
  overflow: auto;
  max-width: 1650px;
}
</style>