import { http } from './config'

export default {


    listar:() => {
        return http.get('listar')
    },

    salvarLeito:(fugulim) => {
        return http.post('leito',fugulim)
    },

    colabolador:() => {
        return http.get('colab')
    },

    salvarColaborador:(colaborador) => {
        return http.post('colaborador',colaborador)
    },

    listarUnidade:() => {
        return http.get('unidades');

    },

    listarSetor:(unidade_id) => {
        return http.get(`setores/${unidade_id}`);

    },

    deleteLeito:(id) => {
        return http.delete(`leito/${id}`)
    },

    getDados:() => {
        return http.get('pdf/dados')
    },
    AtualizaInfo:(id,fugulin) => {
        return http.put(`/atualiza/${id}`,fugulin)
    },
    salvarSetor:(dados) => {
        return http.post('/leito/setor',dados)
    },


}