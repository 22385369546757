<template>
  <!-- <header class="navbar navbar-inverse navbar-fixed-top bs-docs-nav mb-5" role="banner">
  <div class="container bg-success"  >
    <a href="/home"> 
      <img style="width: 25%" src="../../public/segeam-bg-branco.png" alt="">
    </a>
    <p class="text-center text-light">{{thisTitle}}</p>
     <a class="btn btn-success" @click="logout">Sair</a>
  </div>
</header> -->
<div class="navbar-wrapper bg-success mb-5">
<div class="container bg-success">
<nav class="navbar navbar-expand navbar-light w-100 justify-content-between bg-success">
  <button class="navbar-toggler w-100" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
    <div class="d-flex w-100 justify-content-between">

    <a class="btn btn-success" @click="logout">Sair</a>
    <p class="text-center text-light">{{thisTitle}}</p>
    </div>
  </button>

  <div class="collapse navbar-collapse justify-content-between" id="navbarTogglerDemo03">
    <a @click="home()" style="max-width: 100px;" class="mw-25">
      <img class="img-fluid" src="../../public/segeam-bg-branco.png" alt="">
  </a>
  <p class="text-center text-light h3">{{thisTitle}}</p>
       <a class="btn btn-success" @click="logout">Sair</a>
  </div>
</nav>
</div>
</div>
</template>

<script>
import Cookie from 'js-cookie';
import axios from 'axios';
export default {

  props: {
   thisTitle: {
    type: String,
   }
  },

    methods:{
        home(){
    
          this.$router.push('home');
        },
        logout() {
      const token = Cookie.get('_myapp_token');
          
       axios({
                    url: '/logout',
                    method: 'POST',
                    headers: {'Content-Type': 'application/json',Authorization: 'Bearer ' + token},
                }).then(response => {
                    // if(response.data.success == 'success'){
                      localStorage.removeItem('info')
                      localStorage.removeItem('id')
                      Cookie.remove('_myapp_token');
                      localStorage.removeItem('setor')
                      localStorage.removeItem('turno')
                      localStorage.removeItem('id')
                      localStorage.removeItem('info')
                      this.$router.push('/')
                    // }
                }).catch(error => {
                     Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Ocorreu um erro ao tentar sair do sistema!',
                    // footer: '<a href="">Why do I have this issue?</a>'
                  })
                })
    }
    }
}
</script>

<style>
@import 'https://fonts.googleapis.com/css?family=Montserrat|Open+Sans';
.navbar-brand {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase
}

.navbar .nav {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.2rem
}

.navbar-inverse {
  background-color: #188754
}

.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>li>a:hover,
.navbar-inverse .navbar-nav>li>a:focus {
  background-color: #002200
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus {
  background-color: #003300
}

.dropdown-menu {
  background-color: #006B00
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  background-color: #002200
}

.navbar-inverse {
  background-image: none;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  background-image: none;
}

.navbar-inverse {
  border-color: #003300
}

.navbar-inverse .navbar-brand {
  color: #FFFFFF
}

.navbar-inverse .navbar-brand:hover {
  color: #FFCC00
}

.navbar-inverse .navbar-nav>li>a {
  color: #FFFFFF
}

.navbar-inverse .navbar-nav>li>a:hover,
.navbar-inverse .navbar-nav>li>a:focus {
  color: #FFCC00
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus {
  color: #FFCC00
}

.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus {
  color: #FFCC00
}

.dropdown-menu>li>a {
  color: #FFFFFF
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  color: #FFCC00
}

.navbar-inverse .navbar-nav>.dropdown>a .caret {
  border-top-color: #FFFFFF
}

.navbar-inverse .navbar-nav>.dropdown>a:hover .caret {
  border-top-color: #FFFFFF
}

.navbar-inverse .navbar-nav>.dropdown>a .caret {
  border-bottom-color: #FFFFFF
}

.navbar-inverse .navbar-nav>.dropdown>a:hover .caret {
  border-bottom-color: #FFFFFF
}
</style>