<template>
<div>
  <div>
    <NavBar thisTitle="Home" ></NavBar>
  </div>

  <div class="container">


   <button type="button" @click="editar()" class="btn btn-success" style="z-index: 99; position: fixed; left: 90%; top:90%"  >
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
          </svg>
   </button>


       <div class="row justify-content-center ">
        <div class="card shadow col col-12 mb-3 p-3">
        <h2 class="mt-4">Cadastro Leito</h2>
        <i class="h6"><strong>Sistema de Classificação do Paciente FUGULIN et al (1994)</strong></i>






        <form @submit.prevent="salvarLeito" class="d-flex justify-content-start " >

          <div class=" justify-content-center p-5" >
            <div>
              <div class="col-sm-6  " >
               <div class="input-group mb-3">
                <span class="input-group-text " id="inputGroup-sizing-default">Leito</span>
                <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder="Insira o número do leito" v-model="fugulim.numero_leito">
              </div>
              </div>
              <div>
                <div class="col-md-12 row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                       <span class="input-group-text " id="inputGroup-sizing-default">Nome</span>
                    <input type="text" placeholder="Nome do paciente" class="form-control" v-model="fugulim.nome" >
                  </div>

                </div>
                  <div class="col-md-4">
                    <div class="input-group mb-3">
                    <span class="input-group-text " id="inputGroup-sizing-default" style="font-size: 10px;">Data de nascimento</span>
                    <input type="date" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder="Insira o Leito" v-model="fugulim.data_nascimento">
                  </div>
                </div>
                  </div>
              </div>

            </div>

<!--            <div class="card">-->
<!--              <div class="d-flex align-middle  container justify-content-start align-center p-3">-->
<!--                <input class="" type="checkbox" value="" id="fugulim.psiq" v-model="fugulim.psiq">-->
<!--                <p class="ml-3" style="width: 60%"><strong>Psiquiátrico - Cliente da área psiquiátrica, com intercorrência clínica ou cirúrgica associada.</strong></p>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="d-flex align-middle">-->
<!--              <input class="form-check-input mr-2" type="checkbox" value="" id="fugulim.berca" v-model="fugulim.berca">-->
<!--              <p class="ml-3 w-100" style="margin-left: -120px"><strong>Psiquiátrico - Criança menor de 6 anos e recém-nascido devem ser classificados com necessidade de cuidado intermediário</strong></p>-->
<!--            </div>-->

<!--ESTADO MENTAL-->
          <div class="col-sm-8 text-start" style="display: block;">
            <div>
              <span > <strong>Estado Mental</strong> </span>
              <div class="mb-4">
                <div class="form-check text-start mb-2">
                  <input class="form-check-input" type="radio" value="1" name="estado_mental" v-model="fugulim.estado_mental">
                  <label class="form-check-label">
                    1 Ponto = O paciente está orientado no tempo e espaço (informa data e hora precisa);
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="2" name="estado_mental" v-model="fugulim.estado_mental">
                  <label class="form-check-label">
                    2 Pontos = Apresenta períodos de desorientações no tempo e espaço;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="3" name="estado_mental" v-model="fugulim.estado_mental">
                  <label class="form-check-label">
                    3 Pontos = Apresenta períodos de inconsciência;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="4" name="estado_mental" v-model="fugulim.estado_mental">
                  <label class="form-check-label">
                    4 Pontos = O paciente está totalmente inconsciente.
                  </label>
                </div>


<!--                OXIGENAÇÃO-->
              </div>
            </div>
            <div>
              <div class="mb-3">
                <span> <strong> Oxigenação </strong> </span>
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="oxiginacao" v-model="fugulim.oxigenacao">
                  <label class="form-check-label">
                    1 Ponto = O paciente não depende de oxigênio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="oxiginacao" v-model="fugulim.oxigenacao">
                  <label class="form-check-label">
                    2 Pontos = Necessita de máscara ou cateter de oxigênio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="oxiginacao" v-model="fugulim.oxigenacao">
                  <label class="form-check-label">
                    3 Pontos = Precisa de uso contínuo de máscara ou cateter de oxigênio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="oxiginacao" v-model="fugulim.oxigenacao">
                  <label class="form-check-label">
                    4 Pontos = Dependente de ventilação mecânica.
                  </label>
                </div>

<!--SINAIS VITAIS-->
              </div>
              <div class="mb-3">
                <span> <strong> Sinais Vitais </strong> </span>
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="sinais_vitais" v-model="fugulim.sinais_vitais" >
                  <label class="form-check-label">
                    1 Ponto = Você precisa aferir sinais vitais de rotina de 8 em 8 horas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="sinais_vitais" v-model="fugulim.sinais_vitais">
                  <label class="form-check-label">
                    2 Pontos = Necessita de aferir sinais vitais de de 6 em 6 horas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="sinais_vitais" v-model="fugulim.sinais_vitais">
                  <label class="form-check-label">
                    3 Pontos = Controle dos sinais vitais de 4 em 4 horas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="sinais_vitais" v-model="fugulim.sinais_vitais">
                  <label class="form-check-label">
                    4 Pontos = Controle dos sinais vitais de 2 em 2 horas.
                  </label>
                </div>

              </div>
            </div>
          </div>


<!--            MOTILIDADE-->
          <div class="col-sm-8 text-start" >
            <div>
              <span><strong>Motilidade</strong></span>
              <div class="mb-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="1" name="motilidade" v-model="fugulim.motilidade">
                  <label class="form-check-label">
                    1 Ponto = O paciente consegue movimentar todos os segmentos do corpo;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="2" name="motilidade" v-model="fugulim.motilidade">
                  <label class="form-check-label">
                    2 Pontos = Apresenta limitação do movimento;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="3" name="motilidade" v-model="fugulim.motilidade">
                  <label class="form-check-label">
                    3 Pontos = Dificuldade para movimentar os seguimentos do corpo, precisa de mudança de decúbito e movimentação passiva realizada com auxílio da enfermagem;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="4" name="motilidade" v-model="fugulim.motilidade">
                  <label class="form-check-label">
                    4 Pontos = Incapacidade de movimentos os segmentos do corpo, precisa de mudança de decúbito programada e movimentação passiva realizada pela enfermagem.
                  </label>
                </div>

              </div>
            </div>
            <div>

<!--              DEAMBULAÇÃO-->
              <div class="mb-3">
                <span><strong>Deambulação</strong></span>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="1" name="deambulacao" v-model="fugulim.deambulacao">
                  <label class="form-check-label">
                    1 Ponto = O paciente está deambulando sem auxílio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="2" name="deambulacao" v-model="fugulim.deambulacao">
                  <label class="form-check-label">
                    2 Pontos = Necessita de auxílio para deambular;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="3" name="deambulacao" v-model="fugulim.deambulacao">
                  <label class="form-check-label">
                    3 Pontos = Utiliza cadeira de rodas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="4" name="deambulacao" v-model="fugulim.deambulacao">
                  <label class="form-check-label">
                    4 Pontos = Está restrito ao leito.
                  </label>
                </div>

<!--                ALIMENTAÇÃO-->
              </div>
              <div class="mb-3">
                <span><strong>Alimentação</strong></span>
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="alimentacao" v-model="fugulim.alimentacao">
                  <label class="form-check-label">
                    1 Ponto = Consegue alimentar-se sozinho;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="alimentacao" v-model="fugulim.alimentacao">
                  <label class="form-check-label">
                    2 Pontos = Precisa de auxílio para levar o alimento até à boca;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="alimentacao" v-model="fugulim.alimentacao">
                  <label class="form-check-label">
                    3 Pontos = Somente consegue se alimentar por sonda nasogástrica;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="alimentacao" v-model="fugulim.alimentacao">
                  <label class="form-check-label">
                    4 Pontos = Necessita de cateter central (alimentação parenteral).
                  </label>
                </div>

              </div>
            </div>
          </div>


<!--            CUIDADO CORPORAL-->
          <div class="col col-sm-8 mb-3 text-start">
            <div>
              <span> <strong> Cuidado Corporal </strong> </span>
              <div class="mb-3">
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="cuidado_corporal" v-model="fugulim.cuidado_corporal">
                  <label class="form-check-label">
                    1 Ponto = Consegue realizar o autocuidado sem nenhum auxílio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="cuidado_corporal" v-model="fugulim.cuidado_corporal">
                  <label class="form-check-label">
                    2 Pontos = Necessita de auxílio durante o banho de chuveiro ou durante a higiene oral;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="cuidado_corporal" v-model="fugulim.cuidado_corporal">
                  <label class="form-check-label">
                    3 Pontos = A enfermagem precisa de dar o banho de chuveiro e realizar a higiene oral;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="cuidado_corporal" v-model="fugulim.cuidado_corporal">
                  <label class="form-check-label">
                    4 Pontos = Necessita de realizar banho no leito e higiente oral pela enfermagem.
                  </label>
                </div>

              </div>
            </div>
            <div>

<!--              ELIMINACAO-->
              <span> <strong> Eliminação </strong> </span>
              <div class="mb-3">
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="eliminacao" v-model="fugulim.eliminacao" >
                  <label class="form-check-label">
                    1 Ponto = O paciente consegue usar o vaso sanitário sem auxílio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="eliminacao" v-model="fugulim.eliminacao" >
                  <label class="form-check-label">
                    2 Pontos = Necessita de auxílio para usar o vaso sanitário;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="eliminacao" v-model="fugulim.eliminacao" >
                  <label class="form-check-label">
                    3 Pontos = Precisa de usar comadre ou realiza as evacuações em fraldas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="eliminacao" v-model="fugulim.eliminacao" >
                  <label class="form-check-label">
                    4 Pontos = Evacuação em fraldas e uso de sonda vesical para controle da diurese.
                  </label>
                </div>

              </div>
               <div class="mb-3">
                 <span> <strong> Terapêutica </strong> </span>
                 <div class="form-check">
                   <input class="form-check-input" value="1" type="radio" name="terapeutica" v-model="fugulim.terapeutica">
                   <label class="form-check-label">
                     1 Ponto = Administração de medicamentos por via intramuscular ou via oral;
                   </label>
                 </div>
                 <div class="form-check">
                   <input class="form-check-input" value="2" type="radio" name="terapeutica" v-model="fugulim.terapeutica">
                   <label class="form-check-label">
                     2 Pontos = Administração de medicamentos por via endovenosa intermitente;
                   </label>
                 </div>
                 <div class="form-check">
                   <input class="form-check-input" value="3" type="radio" name="terapeutica" v-model="fugulim.terapeutica">
                   <label class="form-check-label">
                     3 Pontos = Administração de medicamentos por via endovenosa contínua ou através de sonda nasogástrica;
                   </label>
                 </div>
                 <div class="form-check">
                   <input class="form-check-input" value="4" type="radio" name="terapeutica" v-model="fugulim.terapeutica">
                   <label class="form-check-label">
                     4 Pontos = Uso de medicamentos vasoativos para a manutenção da pressão arterial.
                   </label>
                 </div>

              </div>
            </div>   
          </div>
            <div class="bd-example card p-3" style="justify-content: start!important;">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" true-value="1" false-value="0" id="fugulim.psiq" v-model="fugulim.psiq">
                <label class="form-check-label text-start" for="flexCheckDefault">
                  Cliente da área psiquiátrica, com intercorrência clínica ou cirúrgica associada,
                  deve ser classificado um nível acima no SCP ( Sistema de Classificação de
                  Pacientes), iniciando-se com cuidados intermediários.
                </label>
              </div>

              <div class="form-check mt-3 " >
                <input class="form-check-input" type="checkbox" true-value="1" false-value="0" v-model="fugulim.berca" >
                <label class="form-check-label text-start" for="flexCheckDefault" style="justify-content: start!important;">
                  Berçário e Unidade de Pediatria, criança menor de 6 anos e recém-nascido devem ser classificados com necessidade de
                  cuidado intermediário, independente da presença do acompanhante.
                </label>
              </div>
              <div>
                <h4 class="mt-5">COFEN, resolução 543/2017</h4>

              </div>
            </div>
           <div class="" style="display: flex; flex-direction: column;" >
           <button type="submit" class="btn btn-outline-success mt-3" >
             CADASTRAR
           </button>
         </div>
         </div>
        </form>
      </div>  
      <!-- <a class="mb-5" href="/about">  -->
      <button  class="btn btn-success w-75 mb-5" @click="next()" >VERIFICAR LEITOS CADASTRADOS</button>
      <!-- </a> -->
    </div>

    <!-- =====================================================================================================================================================================================================================/ -->



   


    <!-- =====================================================================================================================================================================================================================/ -->
    
    

   
  </div>
    </div>


</template>


<script>

import Rota from '../service/rotas.js'
import Table from '@/views/About.vue'
import axios from 'axios';
import Cookie from 'js-cookie';
import NavBar  from '../components/navBar'



export default {
  el: '#nav',


  methods:{

    next(){
      this.$router.push('about');
    },
 
    editar(){
      const  route = this.$router.resolve({
        name: 'Editor',
      })
      window.open(route.href,'_blank');
    },


  async  getUser(){
            const token = Cookie.get('_myapp_token');
         await   axios({
              url: '/me',
              method: 'GET',
              headers: {'Content-Type': 'application/json',Authorization: 'Bearer ' + token},
            })
            .then(response => {
               let element = response.data.colab
               let profdepto = response.data.prof
               this.escala = response.data.escala
            //    console.log("ESCALA",this.escala)
               for (let index = 0; index < element.length; index++) {
                  let e = element[index];
                     localStorage.setItem('id',e.id)
                 for(let i =0; i< profdepto.length;i++){
                  for(let j=0;j<this.escala.length;j++){
                    let escala = this.escala[j]
                    let p = profdepto[i]
                 
                    if(p.id == escala.profdepto_id){
                    //   console.log("ESCALA",escala)
                      this.scale = true
                   localStorage.setItem('id',e.id)
                   localStorage.setItem('setor',p.setor_id)
                   let turno = p.horainicio >='18:00:00' ? 'Noturno' : 'Diurno'
                    localStorage.setItem('turno',turno)

              
                 }
                  }
                 }
               }
            
            })
            .catch(function(error) {
                swal.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro',
                    text: 'Não foi possível listar os usuários , tente mais tarde',
                })
            });
    },

    listar(){
      Rota.listar().then(resposta => {
        this.fugulims = resposta.data
       
      })
    },

    listarSetores(){

      Rota.listarSetor().then(resposta => {

        this.setores = resposta.data
        //  console.log(this.setores)
      })

    },
    salvarColaborador(){

      
      if(!this.colaborador.nome && !this.colaborador.coren && !this.colaborador.matricula ){
        Swal.fire("Falha :(", "Por favor, preencha TODOS os campos!", "error")
      }
      else if(!this.colaborador.nome){
          Swal.fire("Falha :(", "Por favor, digite um NOME válido!", "error")
      }
      else if ( isNaN(this.colaborador.coren) || !this.colaborador.coren){
         Swal.fire("Falha :(", "Por favor, digite um COREN válido!", "error")
      }
      else if ( isNaN(this.colaborador.matricula) || !this.colaborador.matricula){
         Swal.fire("Falha :(", "Por favor, digite um MATRÍCULA válida!", "error")
      }
      else{
        
        Rota.salvarColaborador(this.colaborador).then(resposta => {
          this.fugulim.colaborador_id = resposta.data.colaborador.id
        })
        Swal.fire("Sucesso", "Colaborador cadastrado com sucesso!", "success")
      }
    },

   async salvarLeito(){

          const token = Cookie.get('_myapp_token');
          var id = 0
          
    await   axios({
                    url: '/me',
                    method: 'GET',
                    headers: {'Content-Type': 'application/json',Authorization: 'Bearer ' + token},
                }).then(response => {
                 
                //  id = response.data[0].id
                // console.log(id)
            if(!this.fugulim.numero_leito){
            Swal.fire("Falha :(", "Por favor, digite o NÚMERO DO LEITO corretamente!", "error")
          }
           else if(
            !this.fugulim.estado_mental ||
            !this.fugulim.oxigenacao ||
            !this.fugulim.sinais_vitais ||
            !this.fugulim.motilidade ||
            !this.fugulim.deambulacao ||
            !this.fugulim.alimentacao ||
            !this.fugulim.cuidado_corporal ||
            !this.fugulim.terapeutica ||
            !this.fugulim.eliminacao
          ){
            Swal.fire("Falha :(", "Por favor, cadastre TODOS os campos corretamente!", "error")
          }else{
            //
          const user_id = localStorage.getItem('id')
          const dados = JSON.parse(localStorage.getItem('info'))
          const setor = localStorage.getItem('setor')
          let turno = localStorage.getItem('turno')
            if(dados){
            Rota.salvarLeito({

              'user_id' : user_id,
              'numero_leito': this.fugulim.numero_leito,
              'data_nascimento': this.fugulim.data_nascimento,
              'nome': this.fugulim.nome,
              'estado_mental': this.fugulim.estado_mental,
              'oxigenacao' : this.fugulim.oxigenacao,
              'sinais_vitais' : this.fugulim.sinais_vitais,
              'motilidade': this.fugulim.motilidade,
              'deambulacao' : this.fugulim.deambulacao,
              'eliminacao' : this.fugulim.eliminacao,
              'alimentacao' : this.fugulim.alimentacao,
              'cuidado_corporal' : this.fugulim.cuidado_corporal,
              'terapeutica' : this.fugulim.terapeutica,
              'psiq' : this.fugulim.psiq,
              'berca' : this.fugulim.berca,
              'setor_id': dados['setor'],
              'turno': dados['turno']
            }).then(resposta => {
                 Swal.fire("Sucesso", "Leito cadastrado com sucesso!", "success")
                //  this.saveSetor(dados['setor'],user_id,dados['turno'])
                 this.fugulim = {}
            }).catch(error => {
            })
            }else{
              Rota.salvarLeito({
              'user_id' : user_id,
              'numero_leito': this.fugulim.numero_leito,
              'data_nascimento': this.fugulim.data_nascimento,
              'nome': this.fugulim.nome,
              'estado_mental': this.fugulim.estado_mental,
              'oxigenacao' : this.fugulim.oxigenacao,
              'sinais_vitais' : this.fugulim.sinais_vitais,
              'motilidade': this.fugulim.motilidade,
              'deambulacao' : this.fugulim.deambulacao,
              'eliminacao' : this.fugulim.eliminacao,
              'alimentacao' : this.fugulim.alimentacao,
              'cuidado_corporal' : this.fugulim.cuidado_corporal,
              'terapeutica' : this.fugulim.terapeutica,
              'psiq' : this.fugulim.psiq,
              'berca' : this.fugulim.berca,
              'setor_id': setor,
              'turno': turno
            }).then(resposta => {
                 Swal.fire("Sucesso", "Leito cadastrado com sucesso!", "success")
                //  this.saveSetor(dados['setor'],user_id,dados['turno'])
                 this.fugulim = {}
            }).catch(error => {
            })
            }
           
        }
                })
                .catch(function(error) {
                     swal.fire({
                      icon: 'error',
                      title: 'Ocorreu um erro',
                      text: 'Não foi possível salvar o leito, tente mais tarde',
                  })
              });
    },
    
   
    saveSetor(setor,user_id,turno){
      Rota.salvarSetor({
        'setor_id' : setor,
        'user_id' : user_id,
        'turno' : turno,
      }).then(response => {
      }).catch(function(error){
      })
    },

  },



  name: 'Home',
  components: {
    Table,
    NavBar
  },

  data(){
    return{
      fugulim:{
          id: '',
          numero_leito: 0,
          estado_mental: 0,
          data_nascimento: '' ,
          nome: "",
          oxigenacao: 0,
          sinais_vitais: 0,
          motilidade: 0,
          deambulacao: 0,
          alimentacao: 0,
          cuidado_corporal: 0,
          eliminacao: 0,
          terapeutica: 0,
          total: 0,
          psiq: false,
          berca: false,
          place:"",
        
      },

      data_atual: '',
      fulugim: [],
      colaborador: [],
      fugulims: [],
      unidades: [],
      setores: [],
      user: [],

      colaborador:{
          id: '',
          nome: '',
          coren: '',
          matricula: '',
          horas_trabalhadas: '',
          setor: '',
          unidade: '',
      },

      dados: [],
      escala: [],
      form:false,
      scale: false,
      setor: 0,
      turno:""
    }

},
  created(){
     var today = new Date();
     var date = today.getDate() + ' / ' + (today.getMonth()+1) + ' / ' + today.getFullYear();
     this.getUser();
     this.listarSetores();
    localStorage.removeItem('id')


  },
  

}
</script>

<style>

.form-check{
  margin-bottom: 15px;
}

</style>
