<template>
<div>
  <div>
    <NavBar thisTitle="Home" ></NavBar>
  </div>

<div class="container" v-if="scale">
<div class="row mb-5">
    <div class="col" >
        <div class="card card-margin shadow col col-12 mb-3"  v-for="users in dados" :key="users.id">
           
            <div class="card-header no-border">
                <h5 ><strong><i>{{users.nome}}</i></strong> <br> Você está no seu plantão da escala, conforme listado abaixo?</h5>
            </div>
            <div class="card-body pt-0">
                <div class="p-2">
                    <ul class="list-group">
                        <li class="list-group-item"><span><strong>Matrícula:</strong> {{users.matricula}}</span></li>
                         <li class="list-group-item"><span><strong>Turno:</strong> {{ users.turno >='18:00:00' ? 'Noturno' : 'Diurno'}}</span></li>
                        <li class="list-group-item"><span><strong>Plantāo:</strong> {{users.plantao}} Hora(s)</span></li>
                        <li class="list-group-item"><span><strong>Unidade:</strong> {{users.unidade}}</span></li>
                        <li class="list-group-item"><span><strong>Setor:</strong> {{users.setor}}</span></li>
                        <li class="list-group-item"><button class="btn btn-success" @click="mostraForm">Sim, estou no meu plantão</button></li>
                        <!-- <li class="list-group-item"><span><strong>Turno:</strong> {{ users.profdepto[0].horainicio >='18:00:00' ? 'Noturno' : 'Diurno'}}</span></li> -->
                        <!-- <li class="list-group-item"><span><strong>Plantāo:</strong> {{users.profdepto[0].plantao}} Hora(s)</span></li> -->
                        <!-- <li class="list-group-item"><span><strong>Unidade(es):</strong> {{ users.profdepto[0].setor.unidade.nome }}</span></li> -->
                        <!-- <li class="list-group-item"><span><strong>Setor(es):</strong> {{ users.profdepto[0].setor.nome }}</span></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
  <div class="row" >
 
    <div class="col">
         <div class="card card-margin shadow col col-12 mb-3" >
             <div class="card-header no-border">
              <h5 ><strong><i>Se você está trabalhando em outro setor, selecione abaixo:</i></strong> </h5>
               </div>
              <div class="card-body" >
                  <div  class="col-md-12 p-2">
                    <label for="setor" class="float-start mb-2"><strong><i>Unidade</i></strong></label>
                      <select name="" id="" v-model="unidade" class="form-select form-selectmb-3">
                        <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">{{unidade.nome}}</option>
                      </select>
                  </div>
                  <div class="col-md-12 p-2">
                    <label for="setor" class="float-start mb-2"><strong><i>Setor</i></strong></label>
                      <select name="" id="setor" v-model="setor" class="form-select form-selectmb-3">
                        <option v-for="setor in setores" :key="setor.id" :value="setor.id">{{setor.nome}}</option>
                      </select>
                  </div>
                  <div class="col-md-12 p-2 mt-3 mb-3">
                    <label for="setor" class="float-start mb-2"><strong><i>Turno</i></strong></label>
                      <select name="" id="" v-model="turno" class="form-select form-selectmb-3">
                        <option >DIURNO</option>
                         <option >NOTURNO</option>
                      </select>
                  </div>
                  <div class="col-md-12 mb-3 ">
                    <button class="btn btn-success w-90" @click="next(setor,turno)">Sim, estou em outro setor</button>
                  </div>
              </div>
           
         </div>
    </div>
    
  </div>
  </div>
  <div class="container" v-else>
    <div class="row" >
 
    <div class="col">
         <div class="card card-margin shadow col col-12 mb-3" >
             <div class="card-header no-border">
              <h5 ><strong><i>Se você está trabalhando em outro setor, selecione abaixo:</i></strong> </h5>
               </div>
              <div class="card-body" >
                   <div class="col-md-12 p-2">
                    <label for="setor" class="float-start mb-2"><strong><i>Unidade</i></strong></label>
                      <select name="" id="" v-model="unidade" class="form-select form-selectmb-3">
                        <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">{{unidade.nome}}</option>
                      </select>
                  </div>
                  <div class="col-md-12 p-2">
                    {{setor.id}}
                    <label for="setor" class="float-start mb-2"><strong><i>Setor</i></strong></label>
                      <select name="" id="setor" v-model="setor" class="form-select form-selectmb-3">
                        <option v-for="setor in setores" :key="setor.id" :value="setor.id">{{setor.nome}}</option>
                      </select>
                  </div>
                  <div class="col-md-12 p-2 mt-3 mb-3">
                    <label for="setor" class="float-start mb-2"><strong><i>Turno</i></strong></label>
                      <select name="" id="" v-model="turno" class="form-select form-selectmb-3">
                        <option >DIURNO</option>
                         <option >NOTURNO</option>
                      </select>
                  </div>
                  <div class="col-md-12 mb-3 ">
                    <button class="btn btn-success w-90" @click="next(setor,turno)">Sim, estou em outro setor</button>
                  </div>
              </div>
           
         </div>
    </div>
    </div>
  </div>
</div>

</template>


<script>

import Rota from '../service/rotas.js'
import Table from '@/views/About.vue'
import axios from 'axios';
import Cookie from 'js-cookie';
import NavBar  from '../components/navBar'



export default {
  el: '#nav',

  watch:{
    unidade(oldVal, newVal){
         if (newVal != oldVal ) {
            document.getElementById("setor").options.length = 0;
           }
     
       Rota.listarSetor(this.unidade).then(resposta => {
         let setores =resposta.data.sucssess
        for(let i =0; i< setores.length;i++){
          let set = setores[i]
         
            this.setores.push({id:set.id,nome:set.nome})
          
        }
      
      // this.unidade=""
      })
      
    
    }
  },
  methods:{

    //  saveData(setor,turno){

    //   this.setor = setor;
    //   this.turno = turno
    //   const info = {
    //     setor:this.setor,
    //     turno:this.turno
    //   }

    //   console.log(this.setor,this.turno)
    //   localStorage.setItem('info',JSON.stringify(info))
      
    // },
  next(setor,turno){

    if(!this.unidade||!this.setor||!this.turno){
      Swal.fire("Falha :(", "Por favor, verifique se todos os dados estão preenchidos!", "error");
    }else{
            this.$router.push('form');
             const info = {
               setor:setor,
               turno:turno
             }
       localStorage.setItem('info',JSON.stringify(info))
    }


    },
    mostraForm(){
        this.$router.push('form');
    },
  async  getUser(){

            const token = Cookie.get('_myapp_token');
         await   axios({
              url: '/me',
              method: 'GET',
              headers: {'Content-Type': 'application/json',Authorization: 'Bearer ' + token},
            })
            .then(response => {
               let element = response.data.colab
               let profdepto = response.data.prof
               this.escala = response.data.escala
               for (let index = 0; index < element.length; index++) {
                  let e = element[index];
                 for(let i =0; i< profdepto.length;i++){
                  for(let j=0;j<this.escala.length;j++){
                    let escala = this.escala[j]
                    let p = profdepto[i]
                    // console.log(escala.profdepto_id)
                  if(p.id == escala.profdepto_id){
                      // this.listarSetores(p.setor.unidade.id)
                      this.scale = true
                   localStorage.setItem('id',e.id)
                   this.dados.push({id:e.id,nome:e.nome,matricula:e.matricula,turno:p.horainicio,plantao:p.plantao,unidade:p.setor.unidade.nome,setor:p.setor.nome})
                 }
                  }
                 }
               }
            })
            .catch(function(error) {
                swal.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro',
                    text: 'Não foi possível identificar o usuário, tente mais tarde',
                })
            });
    },
    listarUnidades(){
      Rota.listarUnidade().then(response => {
        let unidade = response.data
        for(let i=0;i<unidade.length;i++){
          let u = unidade[i]
          this.unidades.push({id:u.id,nome:u.nome})
          this.unidades.sort(function(a,b) {
            if(a.nome < b.nome) return -1;
            if(a.nome > b.nome) return 1;
            return 0;
        });
          // this.unidade_id = u.id
    
        }
      })
    },
  //  listarSetores(id){

  //    if(id == null){
  //       Rota.listarSetor().then(resposta => {

  //         this.setores = resposta.data
  //       })
  //    }else{
  //     Rota.listarSetor().then(resposta => {

  //       let setores = resposta.data
 
  //       for(let i =0; i< setores.length;i++){
  //         let set = setores[i]
  //         if(set.unidade_id == id){
  //           // console.log('TesteSetores', set)
  //           this.setores.push({id:set.id,nome:set.nome})
  //         }
  //       }
         
  //     })
  //    }

  //   },
  
    saveData(setor,turno){

      this.setor = setor;
      this.turno = turno
      const info = {
        setor:this.setor,
        turno:this.turno
      }

      localStorage.setItem('info',JSON.stringify(info))
    },
  
  },



  name: 'Home',
  components: {
    Table,
    NavBar
  },

  data(){
    return{
      fugulim:{
          id: '',
          numero_leito: 0,
          estado_mental: 0,
          data_nascimento: '' ,
          nome: "",
          oxigenacao: 0,
          sinais_vitais: 0,
          motilidade: 0,
          deambulacao: 0,
          alimentacao: 0,
          cuidado_corporal: 0,
          eliminacao: 0,
          terapeutica: 0,
          total: 0,
          psiq: false,
          berca: false,
          place:"",
        
      },

      data_atual: '',
      fulugim: [],
      colaborador: [],
      fugulims: [],
      unidades: [],
      setores: [],
      user: [],

      colaborador:{
          id: '',
          nome: '',
          coren: '',
          matricula: '',
          horas_trabalhadas: '',
          setor: '',
          unidade: '',
      },

      dados: [],
      escala: [],
      form:false,
      scale: false,
      setor: 0,
      turno:"",
      unidade_id: 0,
      unidade: 0
      

    }
},
  created(){
     var today = new Date();
     var date = today.getDate() + ' / ' + (today.getMonth()+1) + ' / ' + today.getFullYear();
     this.getUser();
    //  this.listarSetores();
     this.listarUnidades();
     localStorage.removeItem('info')
    localStorage.removeItem('info')


  },
  

}
</script>

<style>

.form-check{
  margin-bottom: 15px;
}

</style>
