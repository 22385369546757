<template>
<div id="app">
  
        <NavBar v-if="this.$router.currentRoute.path=== '/home'" />

  
    

</div>
<router-view/>
</template>
<script>
import NavBar from './components/navBar'

export default {

  components:{
    NavBar
  },
  
  data(){



    return{
      homeverify: true    
    }
},
  methods:{
    trocar(){
      this.homeverify = !this.homeverify
    }
  }
}
</script>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2C3E50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2C3E50;
}
#nav a.router-link-exact-active {
  color: #42B983;
}
</style>