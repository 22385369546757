 <template>
    <div>
      
    </div>
   <div class="container">
     <div class="row">
      <div class="mw-50">
      <a class="mb-5 d-flex justify-content-start" @click="home()">
         <button class="btn btn-success mt-5">Fechar</button>
      </a>
      <img  src="../../public/Escala_Fugulin.jpg" alt="Escala Fugulin">
     </div>
     </div>
   </div>
</template>

 <script>

 import NavBar  from '../components/navBar'

 export default {

   methods:{
     home(){
       window.close();
     },

   },

   name: 'Editar',
   components: {
     NavBar,
   }
 }
 </script>


