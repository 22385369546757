import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import About from '../views/About.vue'
import Leito from '../views/Leito.vue'
import Formulario from '../views/Formulario.vue'
import Guard from '../service/middleware'
import Editar from "@/views/Editar";
const routes = [

  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: Guard.auth
  },
  {
    path: '/form',
    name: 'Formulario',
    component: Formulario,
    beforeEnter: Guard.auth
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
    beforeEnter: Guard.auth
  },
  {
    path: '/editar',
    name: 'Editor',
    component: Editar,
  },
  {
    path: '/leito',
    name: 'Leito',
    component: Leito,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next)=>{
//   if(!auth.loggedIn()){
//     next('/');
//   }else{
//     next();
//   }
// })

export default router
