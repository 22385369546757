<template>
  <div>
    <NavBar thisTitle="Editar Leito" ></NavBar>
  </div>

  <div class="container col-md-8" >
    <a class="mb-5 d-flex justify-content-start" @click="back()" >
      <button class="btn btn-success">Voltar</button>
    </a>
  <div class="row justify-content-center ">
    <div class="card shadow col col-12 mb-3 p-3">
      <h2 class="mt-4">Editar Leito: {{numero_leito}}</h2>
      <i class="h6"><strong>Sistema de Classificação do Paciente FUGULIN et al (1994)</strong></i>
      <p ><a @click="editar()"><strong class="text-success" >Acesse a escala Fugulin por AQUI!</strong> </a></p>





      <form @submit.prevent="salvarLeito" class="d-flex justify-content-center " >
        <div class=" justify-content-center p-5" >
            <div>
              <div class="col-sm-4  " >
               <div class="input-group mb-3">
                <span class="input-group-text " id="inputGroup-sizing-default">Leito</span>
                <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder="Insira o Leito" v-model="fugulim.numero_leito">
              </div>
              </div>
              <div>
                <div class="col-md-12 row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                       <span class="input-group-text " id="inputGroup-sizing-default">Nome</span>
                    <input type="text" v-model="fugulim.nome" placeholder="Nome do paciente" class="form-control">
                      </div>
                </div>
                  <div class="col-md-4 mb-5">
                    <div class="input-group mb-3">
                    <span class="input-group-text " id="inputGroup-sizing-default" style="font-size: 10px;">Data de nascimento</span>
                    <input type="date" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder="Insira o Leito" v-model="fugulim.data_nascimento">
                  </div>
                </div>
                  </div>
              </div>

            </div>


          <!--            <div class="card">-->
          <!--              <div class="d-flex align-middle  container justify-content-start align-center p-3">-->
          <!--                <input class="" type="checkbox" value="" id="fugulim.psiq" v-model="fugulim.psiq">-->
          <!--                <p class="ml-3" style="width: 60%"><strong>Psiquiátrico - Cliente da área psiquiátrica, com intercorrência clínica ou cirúrgica associada.</strong></p>-->
          <!--              </div>-->
          <!--            </div>-->

          <!--            <div class="d-flex align-middle">-->
          <!--              <input class="form-check-input mr-2" type="checkbox" value="" id="fugulim.berca" v-model="fugulim.berca">-->
          <!--              <p class="ml-3 w-100" style="margin-left: -120px"><strong>Psiquiátrico - Criança menor de 6 anos e recém-nascido devem ser classificados com necessidade de cuidado intermediário</strong></p>-->
          <!--            </div>-->

          <!--ESTADO MENTAL-->
          <div class="col-sm-8 text-start" style="display: block;">
            <div>
              <span > <strong>Estado Mental</strong> </span>
              <div class="mb-4">
                <div class="form-check text-start mb-2">
                  <input class="form-check-input" type="radio" value="1" name="estado_mental" v-model="fugulim.estado_mental">
                  <label class="form-check-label">
                    1 Ponto = O paciente está orientado no tempo e espaço (informa data e hora precisa);
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="2" name="estado_mental" v-model="fugulim.estado_mental">
                  <label class="form-check-label">
                    2 Pontos = Apresenta períodos de desorientações no tempo e espaço;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="3" name="estado_mental" v-model="fugulim.estado_mental">
                  <label class="form-check-label">
                    3 Pontos = Apresenta períodos de inconsciência;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="4" name="estado_mental" v-model="fugulim.estado_mental">
                  <label class="form-check-label">
                    4 Pontos = O paciente está totalmente inconsciente.
                  </label>
                </div>


                <!--                OXIGENAÇÃO-->
              </div>
            </div>
            <div>
              <div class="mb-3">
                <span> <strong> Oxigenação </strong> </span>
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="oxiginacao" v-model="fugulim.oxigenacao">
                  <label class="form-check-label">
                    1 Ponto = O paciente não depende de oxigênio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="oxiginacao" v-model="fugulim.oxigenacao">
                  <label class="form-check-label">
                    2 Pontos = Necessita de máscara ou cateter de oxigênio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="oxiginacao" v-model="fugulim.oxigenacao">
                  <label class="form-check-label">
                    3 Pontos = Precisa de uso contínuo de máscara ou cateter de oxigênio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="oxiginacao" v-model="fugulim.oxigenacao">
                  <label class="form-check-label">
                    4 Pontos = Dependente de ventilação mecânica.
                  </label>
                </div>

                <!--SINAIS VITAIS-->
              </div>
              <div class="mb-3">
                <span> <strong> Sinais Vitais </strong> </span>
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="sinais_vitais" v-model="fugulim.sinais_vitais" >
                  <label class="form-check-label">
                    1 Ponto = Você precisa aferir sinais vitais de rotina de 8 em 8 horas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="sinais_vitais" v-model="fugulim.sinais_vitais">
                  <label class="form-check-label">
                    2 Pontos = Necessita de aferir sinais vitais de de 6 em 6 horas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="sinais_vitais" v-model="fugulim.sinais_vitais">
                  <label class="form-check-label">
                    3 Pontos = Controle dos sinais vitais de 4 em 4 horas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="sinais_vitais" v-model="fugulim.sinais_vitais">
                  <label class="form-check-label">
                    4 Pontos = Controle dos sinais vitais de 2 em 2 horas.
                  </label>
                </div>

              </div>
            </div>
          </div>


          <!--            MOTILIDADE-->
          <div class="col-sm-8 text-start" >
            <div>
              <span><strong>Motilidade</strong></span>
              <div class="mb-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="1" name="motilidade" v-model="fugulim.motilidade">
                  <label class="form-check-label">
                    1 Ponto = O paciente consegue movimentar todos os segmentos do corpo;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="2" name="motilidade" v-model="fugulim.motilidade">
                  <label class="form-check-label">
                    2 Pontos = Apresenta limitação do movimento;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="3" name="motilidade" v-model="fugulim.motilidade">
                  <label class="form-check-label">
                    3 Pontos = Dificuldade para movimentar os seguimentos do corpo, precisa de mudança de decúbito e movimentação passiva realizada com auxílio da enfermagem;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="4" name="motilidade" v-model="fugulim.motilidade">
                  <label class="form-check-label">
                    4 Pontos = Incapacidade de movimentos os segmentos do corpo, precisa de mudança de decúbito programada e movimentação passiva realizada pela enfermagem.
                  </label>
                </div>

              </div>
            </div>
            <div>

              <!--              DEAMBULAÇÃO-->
              <div class="mb-3">
                <span><strong>Deambulação</strong></span>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="1" name="deambulacao" v-model="fugulim.deambulacao">
                  <label class="form-check-label">
                    1 Ponto = O paciente está deambulando sem auxílio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="2" name="deambulacao" v-model="fugulim.deambulacao">
                  <label class="form-check-label">
                    2 Pontos = Necessita de auxílio para deambular;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="3" name="deambulacao" v-model="fugulim.deambulacao">
                  <label class="form-check-label">
                    3 Pontos = Utiliza cadeira de rodas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="4" name="deambulacao" v-model="fugulim.deambulacao">
                  <label class="form-check-label">
                    4 Pontos = Está restrito ao leito.
                  </label>
                </div>

                <!--                ALIMENTAÇÃO-->
              </div>
              <div class="mb-3">
                <span><strong>Alimentação</strong></span>
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="alimentacao" v-model="fugulim.alimentacao">
                  <label class="form-check-label">
                    1 Ponto = Consegue alimentar-se sozinho;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="alimentacao" v-model="fugulim.alimentacao">
                  <label class="form-check-label">
                    2 Pontos = Precisa de auxílio para levar o alimento até à boca;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="alimentacao" v-model="fugulim.alimentacao">
                  <label class="form-check-label">
                    3 Pontos = Somente consegue se alimentar por sonda nasogástrica;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="alimentacao" v-model="fugulim.alimentacao">
                  <label class="form-check-label">
                    4 Pontos = Necessita de cateter central (alimentação parenteral).
                  </label>
                </div>

              </div>
            </div>
          </div>

          <!--            CUIDADO CORPORAL-->
          <div class="col col-sm-8 mb-3 text-start">
            <div>
              <span> <strong> Cuidado Corporal </strong> </span>
              <div class="mb-3">
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="cuidado_corporal" v-model="fugulim.cuidado_corporal">
                  <label class="form-check-label">
                    1 Ponto = Consegue realizar o autocuidado sem nenhum auxílio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="cuidado_corporal" v-model="fugulim.cuidado_corporal">
                  <label class="form-check-label">
                    2 Pontos = Necessita de auxílio durante o banho de chuveiro ou durante a higiene oral;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="cuidado_corporal" v-model="fugulim.cuidado_corporal">
                  <label class="form-check-label">
                    3 Pontos = A enfermagem precisa de dar o banho de chuveiro e realizar a higiene oral;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="cuidado_corporal" v-model="fugulim.cuidado_corporal">
                  <label class="form-check-label">
                    4 Pontos = Necessita de realizar banho no leito e higiente oral pela enfermagem.
                  </label>
                </div>

              </div>
            </div>
            <div>


              <!--              ELIMINACAO-->
              <span> <strong> Eliminação </strong> </span>
              <div class="mb-3">
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="eliminacao" v-model="fugulim.eliminacao" >
                  <label class="form-check-label">
                    1 Ponto = O paciente consegue usar o vaso sanitário sem auxílio;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="eliminacao" v-model="fugulim.eliminacao" >
                  <label class="form-check-label">
                    2 Pontos = Necessita de auxílio para usar o vaso sanitário;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="eliminacao" v-model="fugulim.eliminacao" >
                  <label class="form-check-label">
                    3 Pontos = Precisa de usar comadre ou realiza as evacuações em fraldas;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="eliminacao" v-model="fugulim.eliminacao" >
                  <label class="form-check-label">
                    4 Pontos = Evacuação em fraldas e uso de sonda vesical para controle da diurese.
                  </label>
                </div>

              </div>
              <div class="mb-3">
                <span> <strong> Terapêutica </strong> </span>
                <div class="form-check">
                  <input class="form-check-input" value="1" type="radio" name="terapeutica" v-model="fugulim.terapeutica">
                  <label class="form-check-label">
                    1 Ponto = Administração de medicamentos por via intramuscular ou via oral;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="2" type="radio" name="terapeutica" v-model="fugulim.terapeutica">
                  <label class="form-check-label">
                    2 Pontos = Administração de medicamentos por via endovenosa intermitente;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="3" type="radio" name="terapeutica" v-model="fugulim.terapeutica">
                  <label class="form-check-label">
                    3 Pontos = Administração de medicamentos por via endovenosa contínua ou através de sonda nasogástrica;
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" value="4" type="radio" name="terapeutica" v-model="fugulim.terapeutica">
                  <label class="form-check-label">
                    4 Pontos = Uso de medicamentos vasoativos para a manutenção da pressão arterial.
                  </label>
                </div>

              </div>
            </div>
          </div>

          <div class="bd-example card p-3" style="justify-content: start!important;">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" true-value="1" false-value="0" id="fugulim.psiq" v-model="fugulim.psiq">
              <label class="form-check-label text-start" for="flexCheckDefault">
                Cliente da área psiquiátrica, com intercorrência clínica ou cirúrgica associada,
                deve ser classificado um nível acima no SCP ( Sistema de Classificação de
                Pacientes), iniciando-se com cuidados intermediários.
              </label>
            </div>

            <div class="form-check mt-3 " >
              <input class="form-check-input" type="checkbox" true-value="1" false-value="0" v-model="fugulim.berca" >
              <label class="form-check-label text-start" style="justify-content: start!important;">
                Berçário e Unidade de Pediatria, sem acompanhante, criança menor de 6 anos
                e recém-nascido devem ser classificados com necessidade de cuidado
                intermediário.
              </label>
            </div>
            <div>

              <h4 class="mt-5">COFEN, resolução 543/2017</h4>

            </div>
          </div>
          <div class="" style="display: flex; flex-direction: column;" >
            <button @click="update()" class="btn btn-outline-success mt-3" >
              ATUALIZAR
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- <a class="mb-5" href="/about">  -->
    <!-- </a> -->
  </div>
  </div>
</template>

<script>
import Rota from "@/service/rotas";
import NavBar from "@/components/navBar";

export default {
  name: "Leito",
  components:{
    NavBar
  },



  mounted(){

    this.fugulim.id = this.$route.params.id
    this.fugulim.numero_leito = this.$route.params.numero_leito
    this.fugulim.data_nascimento = this.$route.params.data_nascimento
    this.fugulim.nome = this.$route.params.nome
    this.fugulim.estado_mental = this.$route.params.estado_mental
    this.fugulim.oxigenacao = this.$route.params.oxigenacao
    this.fugulim.sinais_vitais = this.$route.params.sinais_vitais
    this.fugulim.motilidade = this.$route.params.motilidade
    this.fugulim.deambulacao = this.$route.params.deambulacao
    this.fugulim.alimentacao = this.$route.params.alimentacao
    this.fugulim.cuidado_corporal = this.$route.params.cuidado_corporal
    this.fugulim.eliminacao = this.$route.params.eliminacao
    this.fugulim.terapeutica = this.$route.params.terapeutica
    this.fugulim.berca = this.$route.params.berca
    this.fugulim.psiq = this.$route.params.psiq

  },

  methods:{

    back(){
      this.$router.push('about')
    },



  update(){

        Rota.AtualizaInfo(this.fugulim.id, this.fugulim).then(response => {
          if (response.status == 200) {
            Swal.fire({
              title: 'Dados Atualizados!',
              icon: 'success',
            })
            this.$router.push('about')

          } else {
            Swal.fire({
              title: 'Erro ao atualizar os dados',
              icon: 'warning',

            })
          }
        })
      },

  },


  data() {
    return {
      fugulim:{
        id: '',
        nome: '',
        data_nascimento: '',
        numero_leito: '',
        estado_mental: '',
        oxigenacao: '',
        sinais_vitais: '',
        motilidade: '',
        deambulacao: '',
        alimentacao: '',
        cuidado_corporal: '',
        eliminacao: '',
        terapeutica: '',
        psiq: '',
        berca: '',

      },

    }
  }
}
</script>

<style scoped>

</style>