<template>

  <div class="container d-flex justify-content-center ini">
  <div class="row col-md-4 ">
  <div class="card shadow p-5">
  <form @submit.stop.prevent="submit" class=" col-md-12">
    <img class="mb-4 w-50" src="../../public/FundoVerde.png" alt="" >
    <h1 class="h3 mb-3 fw-normal">Fugulin Login</h1>
    <div class=" d-flex justify-content-center input-group input-group-md mb-3 ">
      <input v-model="matricula" type="text" class="form-control" id="floatingInput" placeholder="Matrícula">
    </div>
    <div class="d-flex justify-content-center input-group input-group-md mb-3 ">
      <input v-model="password" type="password" class="form-control" id="floatingPassword" placeholder="Senha">
    </div>
    <button class="w-100 btn btn-lg btn-success" type="submit">Entrar</button>
  </form>
</div>
</div>
</div>

</template>
<style>
.ini{
      height: 100vh;
      align-items: center;
}
</style>
<script>    
import Cookie from 'js-cookie'; 

export default {
    el:'#nav',
    data(){
        return{
            matricula: "",
            seen: false,
            password: "",
        }
    },

    created(){
      
    },

    methods:{
        submit() {
            const payload = {
                matricula: this.matricula,
                password: this.password,
            }
                fetch('https://fugulin-api.segeam.com.br/api/login',{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access': 'application/json' 
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => response.json())
                    .then(res => {
                        Cookie.set('_myapp_token', res.access_token);
                          if(res.access_token != null){
                            this.$router.push('home');
                           }
                        })
        }
    }
    
}
</script>