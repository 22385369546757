<template>
  <nav aria-label="Page navigation example">

    <ul class="pagination">


        <li v-for="page in pages" :class="{active:source.current_page == page}" class="page-item">
          <a  @click="navigate($event, page)" class="page-link" href="#">{{page}}</a>
        </li>



    </ul>
  </nav>
</template>

<script>
  import { range } from 'lodash'
  export default{
    props: ['source'],

    data(){
      return {
        pages: []
      }
    },

    methods: {
        navigate: function (ev, page) {
        ev.preventDefault()
        // this.$dispatch('navigate', page)
          this.$emit('navigate', page)
      }


    },

    watch:{
      source() {
        this.pages = range(1, this.source.last_page+1)

      }
    }
}
</script>